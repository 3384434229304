import React from "react"
import styled from "styled-components"

function Logo(props) {
  const { height = 22, color = "#000" } = props
  return (
    <Svg
      height={height}
      focusable="false"
      viewBox="0 0 236 48"
      aria-hidden="true"
      color={color}
    >
      <path
        d="M40.3591 10.9999C48.2311 10.9999 52.1671 15.7279 52.1671 25.1839V43.9039C52.1671 45.0079 51.8311 45.8719 51.1591 46.4959C50.4871 47.0719 49.5991 47.3599 48.4951 47.3599C47.4391 47.3599 46.5751 47.0719 45.9031 46.4959C45.2311 45.8719 44.8951 45.0079 44.8951 43.9039V25.2559C44.8951 22.3279 44.3671 20.2159 43.3111 18.9199C42.2551 17.5759 40.5991 16.9039 38.3431 16.9039C35.7031 16.9039 33.6151 17.8159 32.0791 19.6399C30.5431 21.4159 29.7751 23.8639 29.7751 26.9839V43.9039C29.7751 45.0079 29.4391 45.8719 28.7671 46.4959C28.0951 47.0719 27.2071 47.3599 26.1031 47.3599C25.0471 47.3599 24.1831 47.0719 23.5111 46.4959C22.8391 45.8719 22.5031 45.0079 22.5031 43.9039V25.2559C22.5031 22.3279 21.9751 20.2159 20.9191 18.9199C19.8631 17.5759 18.2071 16.9039 15.9511 16.9039C13.3111 16.9039 11.1991 17.8159 9.61506 19.6399C8.07906 21.4159 7.31106 23.8639 7.31106 26.9839V43.9039C7.31106 45.0079 6.97506 45.8719 6.30306 46.4959C5.63106 47.0719 4.76706 47.3599 3.71106 47.3599C2.65506 47.3599 1.76706 47.0719 1.04706 46.4959C0.375063 45.8719 0.0390625 45.0079 0.0390625 43.9039V14.5999C0.0390625 13.5439 0.399063 12.7039 1.11906 12.0799C1.83906 11.4559 2.70306 11.1439 3.71106 11.1439C4.71906 11.1439 5.53506 11.4559 6.15906 12.0799C6.83106 12.6559 7.16706 13.4719 7.16706 14.5279V17.1919C8.22306 15.1759 9.68706 13.6399 11.5591 12.5839C13.4311 11.5279 15.5911 10.9999 18.0391 10.9999C20.7271 10.9999 22.9351 11.5519 24.6631 12.6559C26.4391 13.7599 27.7591 15.4639 28.6231 17.7679C29.6791 15.7039 31.2391 14.0719 33.3031 12.8719C35.3671 11.6239 37.7191 10.9999 40.3591 10.9999Z"
        fill="currentColor"
      />
      <path
        d="M77.389 47.5039C73.933 47.5039 70.885 46.7599 68.245 45.2719C65.653 43.7839 63.637 41.6719 62.197 38.9359C60.805 36.1519 60.109 32.9359 60.109 29.2879C60.109 25.5919 60.805 22.3759 62.197 19.6399C63.637 16.8559 65.653 14.7199 68.245 13.2319C70.885 11.7439 73.933 10.9999 77.389 10.9999C80.845 10.9999 83.869 11.7439 86.461 13.2319C89.101 14.7199 91.117 16.8559 92.509 19.6399C93.949 22.3759 94.669 25.5919 94.669 29.2879C94.669 32.9359 93.949 36.1519 92.509 38.9359C91.117 41.6719 89.101 43.7839 86.461 45.2719C83.869 46.7599 80.845 47.5039 77.389 47.5039ZM77.389 41.7439C80.605 41.7439 83.053 40.6879 84.733 38.5759C86.461 36.4639 87.325 33.3679 87.325 29.2879C87.325 25.2559 86.461 22.1839 84.733 20.0719C83.005 17.9119 80.557 16.8319 77.389 16.8319C74.221 16.8319 71.773 17.9119 70.045 20.0719C68.317 22.1839 67.453 25.2559 67.453 29.2879C67.453 33.3679 68.293 36.4639 69.973 38.5759C71.701 40.6879 74.173 41.7439 77.389 41.7439Z"
        fill="currentColor"
      />
      <path
        d="M118.03 47.5039C114.574 47.5039 111.526 46.7599 108.886 45.2719C106.294 43.7839 104.278 41.6719 102.838 38.9359C101.446 36.1519 100.75 32.9359 100.75 29.2879C100.75 25.5919 101.446 22.3759 102.838 19.6399C104.278 16.8559 106.294 14.7199 108.886 13.2319C111.526 11.7439 114.574 10.9999 118.03 10.9999C121.486 10.9999 124.51 11.7439 127.102 13.2319C129.742 14.7199 131.758 16.8559 133.15 19.6399C134.59 22.3759 135.31 25.5919 135.31 29.2879C135.31 32.9359 134.59 36.1519 133.15 38.9359C131.758 41.6719 129.742 43.7839 127.102 45.2719C124.51 46.7599 121.486 47.5039 118.03 47.5039ZM118.03 41.7439C121.246 41.7439 123.694 40.6879 125.374 38.5759C127.102 36.4639 127.966 33.3679 127.966 29.2879C127.966 25.2559 127.102 22.1839 125.374 20.0719C123.646 17.9119 121.198 16.8319 118.03 16.8319C114.862 16.8319 112.414 17.9119 110.686 20.0719C108.958 22.1839 108.094 25.2559 108.094 29.2879C108.094 33.3679 108.934 36.4639 110.614 38.5759C112.342 40.6879 114.814 41.7439 118.03 41.7439Z"
        fill="currentColor"
      />
      <path
        d="M160.474 41.8159C161.53 41.9119 162.274 42.1999 162.706 42.6799C163.186 43.1119 163.426 43.7359 163.426 44.5519C163.426 45.5119 163.042 46.2559 162.274 46.7839C161.554 47.2639 160.474 47.4559 159.034 47.3599L157.09 47.2159C153.202 46.9279 150.298 45.7519 148.378 43.6879C146.458 41.5759 145.498 38.4799 145.498 34.3999V17.4799H141.178C139.066 17.4799 138.01 16.5439 138.01 14.6719C138.01 13.8079 138.274 13.1359 138.802 12.6559C139.378 12.1279 140.17 11.8639 141.178 11.8639H145.498V4.44792C145.498 3.34392 145.81 2.47992 146.434 1.85592C147.106 1.23192 147.994 0.919922 149.098 0.919922C150.202 0.919922 151.09 1.23192 151.762 1.85592C152.434 2.47992 152.77 3.34392 152.77 4.44792V11.8639H159.898C160.906 11.8639 161.674 12.1279 162.202 12.6559C162.778 13.1359 163.066 13.8079 163.066 14.6719C163.066 15.5839 162.778 16.2799 162.202 16.7599C161.674 17.2399 160.906 17.4799 159.898 17.4799H152.77V34.9039C152.77 37.1599 153.226 38.8159 154.138 39.8719C155.098 40.9279 156.538 41.5279 158.458 41.6719L160.474 41.8159Z"
        fill="currentColor"
      />
      <path
        d="M183.912 47.5039C180.456 47.5039 177.408 46.7599 174.768 45.2719C172.176 43.7839 170.16 41.6719 168.72 38.9359C167.328 36.1519 166.632 32.9359 166.632 29.2879C166.632 25.5919 167.328 22.3759 168.72 19.6399C170.16 16.8559 172.176 14.7199 174.768 13.2319C177.408 11.7439 180.456 10.9999 183.912 10.9999C187.368 10.9999 190.392 11.7439 192.984 13.2319C195.624 14.7199 197.64 16.8559 199.032 19.6399C200.472 22.3759 201.192 25.5919 201.192 29.2879C201.192 32.9359 200.472 36.1519 199.032 38.9359C197.64 41.6719 195.624 43.7839 192.984 45.2719C190.392 46.7599 187.368 47.5039 183.912 47.5039ZM183.912 41.7439C187.128 41.7439 189.576 40.6879 191.256 38.5759C192.984 36.4639 193.848 33.3679 193.848 29.2879C193.848 25.2559 192.984 22.1839 191.256 20.0719C189.528 17.9119 187.08 16.8319 183.912 16.8319C180.744 16.8319 178.296 17.9119 176.568 20.0719C174.84 22.1839 173.976 25.2559 173.976 29.2879C173.976 33.3679 174.816 36.4639 176.496 38.5759C178.224 40.6879 180.696 41.7439 183.912 41.7439Z"
        fill="currentColor"
      />
      <path
        d="M221.529 47.5039C216.633 47.5039 212.625 46.4959 209.505 44.4799C208.641 43.9519 208.017 43.3999 207.633 42.8239C207.297 42.2479 207.129 41.5999 207.129 40.8799C207.129 40.1119 207.345 39.4639 207.777 38.9359C208.209 38.4079 208.761 38.1439 209.433 38.1439C210.057 38.1439 211.041 38.5279 212.385 39.2959C213.825 40.0639 215.217 40.6879 216.561 41.1679C217.953 41.6479 219.681 41.8879 221.745 41.8879C224.049 41.8879 225.849 41.4799 227.145 40.6639C228.441 39.8479 229.089 38.6959 229.089 37.2079C229.089 36.2479 228.825 35.4799 228.297 34.9039C227.817 34.3279 226.953 33.8239 225.705 33.3919C224.457 32.9119 222.609 32.4079 220.161 31.8799C215.937 30.9679 212.889 29.7439 211.017 28.2079C209.193 26.6239 208.281 24.4879 208.281 21.7999C208.281 19.7359 208.881 17.8879 210.081 16.2559C211.281 14.5759 212.937 13.2799 215.049 12.3679C217.161 11.4559 219.561 10.9999 222.249 10.9999C224.169 10.9999 226.041 11.2639 227.865 11.7919C229.689 12.2719 231.297 12.9919 232.689 13.9519C234.273 15.0079 235.065 16.2319 235.065 17.6239C235.065 18.3919 234.825 19.0399 234.345 19.5679C233.913 20.0959 233.385 20.3599 232.761 20.3599C232.329 20.3599 231.897 20.2639 231.465 20.0719C231.033 19.8799 230.457 19.5679 229.737 19.1359C228.441 18.3679 227.217 17.7679 226.065 17.3359C224.961 16.9039 223.569 16.6879 221.889 16.6879C219.873 16.6879 218.241 17.1199 216.993 17.9839C215.793 18.8479 215.193 20.0239 215.193 21.5119C215.193 22.8559 215.745 23.9119 216.849 24.6799C218.001 25.3999 220.137 26.0959 223.257 26.7679C226.473 27.4399 228.993 28.2079 230.817 29.0719C232.641 29.9359 233.937 31.0159 234.705 32.3119C235.521 33.5599 235.929 35.1679 235.929 37.1359C235.929 40.2559 234.609 42.7759 231.969 44.6959C229.377 46.5679 225.897 47.5039 221.529 47.5039Z"
        fill="currentColor"
      />
    </Svg>
  )
}

const Svg = styled.svg`
  width: auto;
  height: ${({ height }) => height}px;
`

export default Logo
