import React from "react"
import styled, { css } from "styled-components"
import { Link, navigate } from "gatsby"

import Logo from "./Logo"
import TabsMenu from "./TabsMenu"
import MenuButton from "./MenuButton"
import MobileMenu from "./MobileMenu"

import { HOME } from "../../constants/navigation"

/**
 *
 * @param {Array} menu [menu handler]
 * @param {String} onLogoPath [internal navgation path]
 *
 * [
 *   {
 *     title: "Products",
 *     id: "products",
 *     highlight: true, // optional overrides with attentionColor from theme.
 *     data: [
 *       { title: "Team Analytics", id: "team-analytics", to: "/team-analytics" },
 *       { title: "Human Capital Indexing", id: "hcn-index", to: "/hcn-indexing" },
 *       {
 *         itemSeperator: true,  // Add optional seperator, works on both levels.
 *         id: "sep1",
 *       },
 *       { title: "Mootos", id: "mootos", to: "/mootos" },
 *     ],
 *   },
 *   {
 *     title: "Support",
 *     id: "support",
 *     to: "/support",
 *   },
 * ]
 *
 */

function NavigationBar(props) {
  const {
    menu = [],
    onLogoPath,
    fixed = false,
    color,
    bgColor,
    noBorder,
  } = props
  const scrollRef = React.useRef(0)
  const wrapperRef = React.useRef()
  const [nestedTabShow, setNestedTabShow] = React.useState("")
  const [showMobileMenu, setShowMobileMenu] = React.useState(false)
  const [activeMobileItem, setActiveMobileItem] = React.useState("")

  const handleNestedTabShow = React.useCallback(
    item => {
      if (item.id === nestedTabShow) {
        return setNestedTabShow("")
      } else {
        return setNestedTabShow(item.id)
      }
    },
    [nestedTabShow]
  )

  const handleMobileMenu = React.useCallback(
    item => {
      if (item.id === activeMobileItem) {
        return setActiveMobileItem("")
      } else {
        return setActiveMobileItem(item.id)
      }
    },
    [activeMobileItem]
  )

  const handleClose = React.useCallback(() => {
    setNestedTabShow("")
  }, [])

  const handleNavigate = React.useCallback(item => {
    if (item.to) {
      setNestedTabShow("")
      setActiveMobileItem("")
      setShowMobileMenu(false)
      navigate(item.to)
    }
  }, [])

  const handleOpenMobileMenu = React.useCallback(() => {
    if (showMobileMenu) {
      if (!fixed) {
        wrapperRef.current.style.position = fixed ? "fixed" : "relative"
        wrapperRef.current.style.top = ""
      }
      setShowMobileMenu(false)
    } else {
      if (!fixed) {
        scrollRef.current = window.scrollY
        wrapperRef.current.style.position = "fixed"
        wrapperRef.current.style.top = 0
      }
      setShowMobileMenu(true)
    }
  }, [showMobileMenu, fixed])

  return (
    <Wrapper fixed={fixed} ref={wrapperRef}>
      <Nav nestedTabNavShow={!!nestedTabShow} color={color} bgColor={bgColor}>
        <Toolbar noBorder={noBorder}>
          <LogoWrapper to={onLogoPath || HOME}>
            <Logo height={24} color="inherit" />
          </LogoWrapper>
          <TabsMenu
            menu={menu}
            onButton={handleNestedTabShow}
            onNavigate={handleNavigate}
            onClose={handleClose}
            nestedActive={nestedTabShow}
          />
          {menu.length > 0 && (
            <MenuButton onClick={handleOpenMobileMenu} open={showMobileMenu} />
          )}
          <MobileMenu
            menu={menu}
            open={showMobileMenu}
            nestedActive={activeMobileItem}
            onButton={handleMobileMenu}
            onNavigate={handleNavigate}
          />
        </Toolbar>
      </Nav>
    </Wrapper>
  )
}

const fixedCss = css`
  position: fixed;
  top: 0;
`

const absoluteCss = css`
  position: relative;
`

const Wrapper = styled.div`
  width: 100%;
  z-index: 1100;
  ${({ fixed }) => (fixed ? fixedCss : absoluteCss)}
`

const Nav = styled.nav`
  background-color: ${({ color, theme }) =>
    color ? theme.palette[color].dark : "rgb(255, 255, 255)"};
  height: ${({ nestedTabNavShow, theme }) =>
    nestedTabNavShow
      ? theme.overrides.NavigationBar.height * 2
      : theme.overrides.NavigationBar.height}px;
  position: relative;
  color: ${({ color, theme }) =>
    color
      ? theme.palette[color].contrastText
      : theme.overrides.NavigationBar.color};
`

const Toolbar = styled.div`
  padding: 0 24px;
  align-items: center;
  ${({ noBorder }) =>
    !noBorder && "box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 20%)"};
  display: flex;
  height: ${({ theme }) => theme.overrides.NavigationBar.height}px;
  color: inherit;
`

const LogoWrapper = styled(Link)`
  color: inherit;
  display: flex;
  text-decoration: none;
`

export default NavigationBar
