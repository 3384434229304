import React from "react"
import styled from "styled-components"

import { NavigationLabel } from "../Typography"
import Caret from "./Caret"

function NavMenuButton(props) {
  const { item, active, onClick = () => {} } = props

  const handleOnClick = React.useCallback(() => {
    onClick(item)
  }, [item, onClick])

  return (
    <MenuItem>
      <MenuButtonOuter>
        <MenuButton
          onClick={handleOnClick}
          active={active}
          highlight={item.highlight}
        >
          <NavigationLabel>{item.title}</NavigationLabel>
          {item.data && (
            <ButtonEndAdornment>
              <Caret active={active} />
            </ButtonEndAdornment>
          )}
        </MenuButton>
      </MenuButtonOuter>
    </MenuItem>
  )
}

function MobileMenuMenu(props) {
  const {
    item,
    nestedActive,
    onButton = () => {},
    onNavigate = () => {},
  } = props
  const ACTIVE = item.id === nestedActive

  return (
    <>
      <NavMenuButton item={item} active={ACTIVE} onClick={onButton} />
      <NestedMenu show={ACTIVE}>
        {item.data.map(subItem => {
          if (subItem.to) {
            return (
              <NavMenuButton
                key={subItem.id}
                item={subItem}
                onClick={onNavigate}
              />
            )
          } else if (subItem.itemSeperator) {
            return (
              <MenuItem key={subItem.id}>
                <ItemSeperator />
              </MenuItem>
            )
          } else {
            return null
          }
        })}
      </NestedMenu>
    </>
  )
}

function MobileMenu(props) {
  const {
    open,
    menu,
    nestedActive,
    onButton = () => {},
    onNavigate = () => {},
  } = props
  return (
    <Wrapper open={open}>
      <NavList>
        {menu.map(item => {
          if (item.data) {
            return (
              <MobileMenuMenu
                key={item.id}
                item={item}
                onButton={onButton}
                onNavigate={onNavigate}
                nestedActive={nestedActive}
              />
            )
          } else if (item.to) {
            return (
              <NavMenuButton
                key={item.id}
                item={item}
                onClick={onNavigate}
                highlight={item.highlight}
              />
            )
          } else if (item.itemSeperator) {
            return (
              <MenuItem key={item.id}>
                <ItemSeperator />
              </MenuItem>
            )
          } else {
            return null
          }
        })}
      </NavList>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  background-color: rgb(255, 255, 255);
  top: ${({ theme }) => theme.overrides.NavigationBar.height}px;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  overflow: hidden scroll;
  padding: 30px;
  position: fixed;
  z-index: 1500;
  ${({ theme }) => theme.overrides.NavigationBar.mobileBreakpoint} {
    visibility: hidden;
    display: none;
  }
  color: ${({ theme }) => theme.palette.text.primary};
`

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`

const MenuItem = styled.li`
  display: inline-block;
  margin-left: 0;
  margin-top: 20px;
  padding: 0;
  white-space: nowrap;
`

const MenuButtonOuter = styled.div`
  background-color: rgb(245, 248, 250);
  box-sizing: border-box;
  padding: 12px 18px;
  width: 100%;
`

const MenuButton = styled.button.attrs(() => ({
  type: "button",
}))`
  background: none;
  border: 0;
  text-align: unset;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0px;
  margin: -1px 0px;
  color: ${({ active, theme, highlight }) => {
    if (highlight) {
      return theme.overrides.NavigationBar.attentionColor
    } else if (active) {
      return theme.overrides.NavigationBar.activeColor
    } else {
      return "inherit"
    }
  }};
  @media (hover: hover) {
    & :hover {
      color: ${({ theme, highlight }) => {
        if (highlight) {
          return theme.overrides.NavigationBar.attentionColor
        } else {
          return theme.overrides.NavigationBar.activeColor
        }
      }};
    }
  }
`

const ButtonEndAdornment = styled.span`
  color: inherit;
`

const NestedMenu = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px 0px 12px 32px;
  display: ${({ show }) => (show ? "flex" : "none")};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  flex-direction: column;
`

const ItemSeperator = styled.span`
  display: inline-block;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: inherit;
  border-left: none;
  width: 100%;
`

export default MobileMenu
