import styled, { css } from "styled-components"

const marginCSS = css`
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing[mt]}`};
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing[mb]}`};
  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing[mr]}`};
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing[ml]}`};
  ${({ theme }) => theme.breakpoint.up.sm} {
    ${({ mt_sm, theme }) => mt_sm && `margin-top: ${theme.spacing[mt_sm]}`};
    ${({ mb_sm, theme }) => mb_sm && `margin-bottom: ${theme.spacing[mb_sm]}`};
    ${({ mr_sm, theme }) => mr_sm && `margin-right: ${theme.spacing[mr_sm]}`};
    ${({ ml_sm, theme }) => ml_sm && `margin-left: ${theme.spacing[ml_sm]}`};
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    ${({ mt_md, theme }) => mt_md && `margin-top: ${theme.spacing[mt_md]}`};
    ${({ mb_md, theme }) => mb_md && `margin-bottom: ${theme.spacing[mb_md]}`};
    ${({ mr_md, theme }) => mr_md && `margin-right: ${theme.spacing[mr_md]}`};
    ${({ ml_md, theme }) => ml_md && `margin-left: ${theme.spacing[ml_md]}`};
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    ${({ mt_lg, theme }) => mt_lg && `margin-top: ${theme.spacing[mt_lg]}`};
    ${({ mb_lg, theme }) => mb_lg && `margin-bottom: ${theme.spacing[mb_lg]}`};
    ${({ mr_lg, theme }) => mr_lg && `margin-right: ${theme.spacing[mr_lg]}`};
    ${({ ml_lg, theme }) => ml_lg && `margin-left: ${theme.spacing[ml_lg]}`};
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    ${({ mt_xl, theme }) => mt_xl && `margin-top: ${theme.spacing[mt_xl]}`};
    ${({ mb_xl, theme }) => mb_xl && `margin-bottom: ${theme.spacing[mb_xl]}`};
    ${({ mr_xl, theme }) => mr_xl && `margin-right: ${theme.spacing[mr_xl]}`};
    ${({ ml_xl, theme }) => ml_xl && `margin-left: ${theme.spacing[ml_xl]}`};
  }
`

/**
 *  NavgationBar
 *  Color is set on btnElement
 */
export const NavigationLabel = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.headings};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.overrides.MenuLink.fontSize};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeight.normal};
  text-decoration: unset;
  margin: 0;
  color: inherit;
`

export const HeroTitle = styled.div.attrs(({ as = "h1" }) => ({
  as,
}))`
  font-family: ${({ theme }) => theme.fontFamily.headings};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: 32px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  text-decoration: unset;
  margin: 0;
  color: inherit;
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 52px;
  }
  ${marginCSS};
`

export const Title = styled.div.attrs(({ as = "h1" }) => ({
  as,
}))`
  font-family: ${({ theme }) => theme.fontFamily.headings};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: 32px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  text-decoration: unset;
  margin: 0;
  color: inherit;
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 52px;
  }
  ${marginCSS};
`

export const Paragraph = styled.div.attrs(({ as = "span" }) => ({
  as,
}))`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-size: 15px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  text-decoration: unset;
  margin: 0;
  display: block;
  color: inherit;
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 18px;
  }
  ${marginCSS};
`
