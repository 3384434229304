import React from "react"
import styled from "styled-components"
// import { Link } from "gatsby"

import { HeroTitle } from "../components/Typography"
import { Main } from "../components/Root"
import NavigationBar from "../components/NavigationBar"
import { menu } from "../navigation/menu"

const HERO_BG =
  "https://firebasestorage.googleapis.com/v0/b/mootos-web.appspot.com/o/assets%2Fstar-pattern.svg?alt=media&token=f38be122-fc7f-44b0-9fe1-ea62251db68e"

const HERO_IMG =
  "https://firebasestorage.googleapis.com/v0/b/mootos-web.appspot.com/o/assets%2Flogo-white.svg?alt=media&token=55053b5c-8254-4435-a7fc-10a845723d93"

const IndexPage = () => {
  return (
    <>
      <Main>
        <HeroSection>
          <HeroInner>
            <HeroImageWrapper>
              <HeroImage />
            </HeroImageWrapper>
            <HeroMessage>
              <HeroTitle>
                Mootos - for trusted transactions in the Open Talent Economy
              </HeroTitle>
            </HeroMessage>
          </HeroInner>
        </HeroSection>
      </Main>
      <NavigationBar menu={menu} fixed color="primary" noBorder />
    </>
  )
}

const BREAKPOINT = "lg"

const HeroSection = styled.div`
  max-height: 1080px;
  align-items: center;
  display: flex;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  background-image: url(${HERO_BG});
  color: #fff;
`

const HeroInner = styled.div`
  flex-direction: column;
  padding: 24px 12px;
  width: 100%;
  align-items: center;
  display: flex;
  margin: 12px auto;
  text-align: center;
  ${({ theme }) => theme.breakpoint.up[BREAKPOINT]} {
    flex-direction: row;
    justify-content: center;
    padding: 40px;
  }
`

const HERO_IMG_SMALL = 162
const HERO_IMG_LARGE = 260

const HeroImageWrapper = styled.div`
  max-height: ${HERO_IMG_SMALL}px;
  ${({ theme }) => theme.breakpoint.up[BREAKPOINT]} {
    max-height: ${HERO_IMG_LARGE}px;
  }
`

const HeroImage = styled.div`
  background: url(${HERO_IMG});
  background-size: ${HERO_IMG_SMALL}px;
  height: ${HERO_IMG_SMALL}px;
  min-width: ${HERO_IMG_SMALL}px;
  width: ${HERO_IMG_SMALL}px;
  ${({ theme }) => theme.breakpoint.up[BREAKPOINT]} {
    background-size: ${HERO_IMG_LARGE}px;
    height: ${HERO_IMG_LARGE}px;
    min-width: ${HERO_IMG_LARGE}px;
    width: ${HERO_IMG_LARGE}px;
  }
`

const HeroMessage = styled.div`
  margin: 12px auto 0;
  max-width: 600px;
  width: 100%;
  ${({ theme }) => theme.breakpoint.up[BREAKPOINT]} {
    margin: auto 48px;
    width: 60%;
  }
`

export default IndexPage
